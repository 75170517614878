import { combineReducers } from 'redux';
// import ReactGA from 'react-ga';
import { GeneralTypes, UserTypes } from '../../actions/types';
import { createVisitorID } from '../../utils/helpers';
import { EnumPaymentMethods } from '../../utils/model';

export interface EventType {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: string;
}

export interface GeneralState {
  appState: AppState;
  appActions: AppActions;
}

export interface ProductBullet {
  content: string;
  checked: boolean;
}
export interface DeliveryOptions {
  id: number;
  name: string;
  description: string;
}

export interface ProductType {
  [x: string]: any;
  channelDto: {
    id: number;
    name: string;
    infos: ProductBullet[];
    includePaper: boolean;
    hightlight: boolean;
  };
  deliveryOptionsDto: DeliveryOptions[];
  duration: number;
  durationName: string;
  editionDto: Array<{ id: number; name: string }>;
  id: number;
  editionID: number;
  name: string;
  online: boolean;
  price: number;
  saturdayPaper: boolean;
  pricePerWeek: false;
  tempAccess: string;
  paymentMethodsDto: EnumPaymentMethods[];
  typeDto: { id: number; name: string };
  productInfos: string;
  thanksYouMessage: string;
  conditions: string;
  gifts: string;
}

export type AppAction = { id: number; definition: string };
export type AppActions = null | Array<AppAction>;

export type BrandType = {
  id: number;
  name: string;
  homeTitle: string;
  homeSubTitle: string;
  homeNeedHelp: string;
  baseline: string;
  rootClassName: string;
  color: string;
  secondColor: string;
  thirdColor: string;
  logoWrapperClassName: string;
  cdvLink: string;
  finishedLink: string;
  finishedText: string;
  hasSubscriberSpace: boolean;
  hasEdition: boolean;
  hasLibraryDelivery: boolean;
  hasSWG: boolean;
  subscriberSpace: string;
  aid: string;
  specialText: string;
  specialLink: string;
  metaTitle: string;
  metaDescription: string;
  ratioLoyalty: number;
  smartFAQ: boolean;
  smartFAQTags: string;
  microHeader: string[];
};

export interface PaymentServiceProvider {
  id: number;
  name: string;
}

export interface PaymentMethod {
  id: number;
  name: string;
  icon: string;
  paymentServiceProvidersDTO: PaymentServiceProvider;
}

export interface AppState {
  intent: string | null;
  transaction: string | null;
  clientSecret: string | null;
  visitor_id: string;
  utm_campaign: string | null;
  utm_content: string | null;
  utm_source: string | null;
  utm_medium: string | null;
  pool_token: string;
  orderState:
    | 'INIT'
    | 'BRAND_SELECTED'
    | 'OFFER_SELECTED'
    | 'EDITION_SELECTED'
    | 'GIFT_SELECTED'
    | 'DURATION_SELECTED'
    | 'PRODUCT_SELECTED'
    | 'PAID'
    | 'FINISHED';
  offer: number | null;
  offerName: string | null;
  intentUpdated: boolean;
  duration: number | null;
  durationName: string | null;
  type: number | null;
  campaign: string | null;
  token: string | null;
  promocode: string | null;
  gift: string | null;
  voucher: number | null;
  hasGift: boolean | null;
  brand: BrandType;
  brandName: string;
  order_id: string | null;
  redirect_url: string | null;
  productID: number | null;
  editionID: number | null;
  currentProduct: ProductType | null;
  descriptions: Array<Array<string>>;
  paymentMethods: Array<number>;
  recurrentOption: number;
  deliveryOption: null | DeliveryOptions;
  paymentType: string | null;
  swgBuy: boolean;
  renewal: boolean;
  swgHasBeenInitialized: boolean;
  pianoInitialized: boolean;
  noAdBlockerInitialized: boolean;
  didomiInitialized: boolean | null;
  gtmInitialized: boolean;
}

const lsCampaign = localStorage.getItem('utm');
const utm =
  lsCampaign !== null
    ? JSON.parse(lsCampaign)
    : {
        utm_campaign: '',
        utm_content: '',
        utm_source: '',
        utm_medium: '',
        pool_token: '',
        promocode: '',
      };

let initOrderState: AppState['orderState'];
const orderState = localStorage.getItem('orderState');

if (orderState === 'INIT') initOrderState = 'INIT';
else if (orderState === 'OFFER_SELECTED') initOrderState = 'OFFER_SELECTED';
else if (orderState === 'EDITION_SELECTED') initOrderState = 'EDITION_SELECTED';
else if (orderState === 'GIFT_SELECTED') initOrderState = 'GIFT_SELECTED';
else if (orderState === 'DURATION_SELECTED')
  initOrderState = 'DURATION_SELECTED';
else if (orderState === 'PRODUCT_SELECTED') initOrderState = 'PRODUCT_SELECTED';
else if (orderState === 'PAID') initOrderState = 'PAID';
else if (orderState === 'FINISHED') initOrderState = 'FINISHED';
else initOrderState = 'INIT';

const brandData = (brand: any) => {
  if (!brand) {
    return {
      id: 0,
      name: 'Default Brand',
      homeTitle: '',
      homeSubTitle: '',
      homeNeedHelp: '',
      baseline: '',
      rootClassName: 'default-brand',
      logoWrapperClassName: '',
      color: '',
      secondColor: '',
      thirdColor: '',
      cdvLink: '',
      finishedLink: '',
      finishedText: '',
      subscriberSpace: '',
      hasSubscriberSpace: false,
      hasEdition: false,
      hasLibraryDelivery: false,
      hasSWG: false,
      aid: '',
      specialText: '',
      specialLink: '',
      metaTitle: '',
      metaDescription: '',
      ratioLoyalty: 0,
      smartFAQ: false,
      smartFAQTags: '',
      microHeader: [],
    };
  }
  // Existing logic to create a brand object from the provided 'brand' parameter
  return {
    id: brand.id,
    name: brand.name,
    homeTitle: brand.homeTitle,
    homeSubTitle: brand.homeSubTitle,
    homeNeedHelp: brand.homeNeedHelp,
    baseline: brand.baseline,
    rootClassName: brand.name,
    logoWrapperClassName: '',
    color: brand.color,
    secondColor: brand.secondColor,
    thirdColor: brand.thirdColor,
    cdvLink: brand.cdvLink,
    finishedLink: brand.finishedLink,
    finishedText: brand.finishedText,
    subscriberSpace: brand.subscriberSpace,
    hasSubscriberSpace: brand.hasSubscriberSpace,
    hasEdition: brand.hasEdition,
    hasLibraryDelivery: brand.hasLibraryDelivery,
    hasSWG: brand.hasSWG,
    aid: brand.aid,
    specialText: brand.specialText,
    specialLink: brand.specialLink,
    metaTitle: brand.metaTitle,
    metaDescription: brand.metaDescription,
    ratioLoyalty: brand.ratioLoyalty,
    smartFAQ: brand.smartFAQ,
    smartFAQTags: brand.smartFAQTags,
    microHeader: brand.microHeader,
  };
};

const defaultState: AppState = {
  orderState: initOrderState,
  intent: localStorage.getItem('intent'),
  intentUpdated: false,
  clientSecret: null,
  campaign: null,
  token: null,
  visitor_id: createVisitorID(),
  currentProduct: null,
  utm_campaign: utm.utm_campaign,
  promocode: utm.promocode,
  type: 1,
  brand: brandData(localStorage.getItem('brand')),
  brandName: '',
  hasGift: false,
  redirect_url: null,
  gift:
    localStorage.getItem('gift') !== null ? localStorage.getItem('gift') : null,
  voucher:
    localStorage.getItem('voucher') !== null
      ? Number(localStorage.getItem('voucher'))
      : null,
  offerName: null,
  offer:
    localStorage.getItem('offer') !== null
      ? Number(localStorage.getItem('offer'))
      : null,
  duration:
    localStorage.getItem('duration') !== null
      ? Number(localStorage.getItem('voucher'))
      : null,
  durationName:
    localStorage.getItem('durationName') !== null
      ? localStorage.getItem('durationName')
      : null,
  productID:
    localStorage.getItem('productID') !== null
      ? Number(localStorage.getItem('productID'))
      : null,
  editionID:
    localStorage.getItem('editionID') !== null
      ? Number(localStorage.getItem('editionID'))
      : null,
  order_id:
    localStorage.getItem('order_id') !== null
      ? localStorage.getItem('order_id')
      : null,
  deliveryOption: null,
  swgHasBeenInitialized: false,
  pianoInitialized: false,
  noAdBlockerInitialized: false,
  didomiInitialized: null,
  gtmInitialized: false,
  swgBuy: false,
  descriptions: [
    [
      'Journal papier du lundi au samedi',
      'Journal numérique du lundi au samedi (9 éditions régionales)',
      'Accès aux articles réservés aux abonnés sur lavenir.net',
    ],
    [
      'Journal numérique du lundi au samedi (9 éditions régionales)',
      'Journal papier du samedi',
      'Accès aux articles réservés aux abonnés sur lavenir.net',
    ],
    [
      'Accès aux articles réservés aux abonnés sur lavenir.net',
      'Journal numérique du samedi',
    ],
  ],
  transaction: null,
  utm_content: utm.utm_content,
  utm_source: utm.utm_source,
  utm_medium: utm.utm_medium,
  pool_token: '',
  paymentMethods: [],
  recurrentOption: 0,
  paymentType: null,
  renewal: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function appState(state: AppState = defaultState, action: any): AppState {
  if (action.type === GeneralTypes.RECEIVED_APP_STATE) {
    return action.data;
  }
  if (action.type === GeneralTypes.SET_CAMPAIGN) {
    const newState = { ...state };
    newState.campaign = action.data.campaign;
    localStorage.setItem('campaign', action.data.campaign);
    return newState;
  }
  if (action.type === GeneralTypes.SET_UTM_DATA) {
    const newState = { ...state };
    // newState.utm_campaign = action.data.utm_campaign;
    newState.utm_content = action.data.utm_content;
    newState.utm_source = action.data.utm_source;
    newState.utm_medium = action.data.utm_medium;
    newState.pool_token = action.data.pool_token;
    newState.promocode = action.data.promocode;
    localStorage.setItem('utm', JSON.stringify(action.data));
    return newState;
  }
  if (action.type === GeneralTypes.SET_DELIVERY_OPTION) {
    const newState = {
      ...state,
      deliveryOption: action.data,
    };

    return newState;
  }
  if (action.type === GeneralTypes.SELECT_PAYMENT_TYPE) {
    return { ...state, paymentType: action.data };
  }
  if (action.type === UserTypes.INIT_APP) {
    const newState = { ...state };
    return newState;
  }
  if (action.type === GeneralTypes.PIANO_INITIALIZED) {
    const newState = {
      ...state,
      pianoInitialized: action.data,
    };
    return newState;
  }
  if (action.type === GeneralTypes.NOADBLOCK_INITIALIZED) {
    const newState = {
      ...state,
      noAdBlockerInitialized: action.data,
    };
    return newState;
  }
  if (action.type === GeneralTypes.GTM_INITIALIZED) {
    const newState = {
      ...state,
      gtmInitialized: action.data,
    };
    return newState;
  }
  if (action.type === GeneralTypes.DIDOMI_INITIALIZED) {
    const newState = {
      ...state,
      didomiInitialized: action.data,
    };
    return newState;
  }
  if (action.type === GeneralTypes.SWG_INITIALIZED) {
    const newState = {
      ...state,
      swgHasBeenInitialized: action.data,
    };
    return newState;
  }
  if (action.type === GeneralTypes.SWG_SUCCESS) {
    const newState = { ...state };
    newState.swgBuy = action.data.swgBuy;
    newState.currentProduct = action.data.currentProduct;
    return newState;
  }
  if (action.type === GeneralTypes.SET_REDIRECT_URL) {
    const newState = { ...state, redirect_url: action.data };
    return newState;
  }
  if (action.type === GeneralTypes.SELECT_OFFER) {
    const newState = { ...state };
    newState.offer = action.data.offerId;
    newState.offerName = action.data.offerName;
    localStorage.setItem('orderState', 'OFFER_SELECTED');
    newState.orderState = 'OFFER_SELECTED';
    newState.duration = null;
    return newState;
  }
  if (action.type === GeneralTypes.SELECT_DURATION) {
    const newState = { ...state };
    newState.duration = action.data.duration;
    newState.durationName = action.data.durationName;
    localStorage.setItem('orderState', 'DURATION_SELECTED');
    newState.orderState = 'DURATION_SELECTED';
    return newState;
  }
  if (action.type === GeneralTypes.SELECT_PRODUCT) {
    const newState = { ...state };
    newState.productID = action.data;
    localStorage.setItem('orderState', 'PRODUCT_SELECTED');
    newState.orderState = 'PRODUCT_SELECTED';
    return newState;
  }
  if (action.type === GeneralTypes.SELECT_EDITION) {
    const newState = { ...state };
    newState.editionID = action.data;
    localStorage.setItem('orderState', 'EDITION_SELECTED');
    newState.orderState = 'EDITION_SELECTED';
    return newState;
  }
  if (action.type === GeneralTypes.SET_VOUCHER) {
    const newState = { ...state };
    newState.voucher = action.data.voucherCode.id;
    newState.renewal = false;
    localStorage.setItem('voucher', action.data.voucherCode.id);
    return newState;
  }
  if (action.type === GeneralTypes.SET_HAS_GIFT) {
    const newState = { ...state };
    newState.hasGift = action.data;
    newState.orderState = 'GIFT_SELECTED';
    return newState;
  }
  if (action.type === GeneralTypes.SELECT_GIFT) {
    const newState = { ...state };
    newState.gift = action.data;
    localStorage.setItem('orderState', 'GIFT_SELECTED');
    newState.orderState = 'GIFT_SELECTED';
    return newState;
  }
  if (action.type === GeneralTypes.REMOVE_GIFT) {
    const newState = { ...state };
    newState.hasGift = false;
    newState.gift = null;
    localStorage.removeItem('hasGift');
    localStorage.removeItem('gift');
    return newState;
  }
  if (action.type === GeneralTypes.SET_BRAND) {
    const newState = { ...state };
    newState.brand = brandData(action.data);
    newState.brandName = action.data.name;
    localStorage.setItem('brand', action.data.id);
    newState.orderState = 'BRAND_SELECTED';
    document.documentElement.style.setProperty('--color', action.data.color);

    document.documentElement.style.setProperty(
      '--primary',
      action.data.secondColor
    );
    document.documentElement.style.setProperty(
      '--primaryrgba40',
      action.data.secondColor + 80
    );

    document.documentElement.style.setProperty(
      '--secondary',
      action.data.thirdColor
    );

    document.documentElement.style.setProperty(
      '--secondaryrgba40',
      action.data.thirdColor + 80
    );

    return newState;
  }
  if (action.type === GeneralTypes.SET_RENEWAL) {
    const newState = { ...state };
    newState.paymentMethods = action.data.paymentMethodsDto;
    newState.renewal = true;
    newState.hasGift = false;
    newState.gift = null;
    newState.currentProduct = action.data;
    return newState;
  }
  if (action.type === GeneralTypes.SET_PRODUCT) {
    const newState = { ...state };
    newState.paymentMethods = action.data.paymentMethodsDto;
    newState.recurrentOption = action.data.recurringOption;
    localStorage.setItem('recurrentOption', action.data.recurringOption);
    newState.currentProduct = action.data;
    return newState;
  }
  if (action.type === GeneralTypes.SET_PRODUCT_DURATION) {
    const newState = { ...state };
    newState.currentProduct = action.data;
    return newState;
  }
  if (action.type === GeneralTypes.SET_CLASSIC_FLOW) {
    const newState = { ...state };
    newState.renewal = false;
    return newState;
  }
  if (action.type === GeneralTypes.CREATE_ORDER_SUCCESS) {
    const newState = { ...state };
    localStorage.setItem('intent', action.data);
    newState.intent = action.data;
    return newState;
  }
  if (action.type === GeneralTypes.CREATE_TRANSACTION_SUCCESS) {
    const newState = { ...state };
    // localStorage.removeItem('transactionID');
    localStorage.setItem('transaction', action.data);
    newState.transaction = action.data;
    return newState;
  }
  if (action.type === GeneralTypes.DELETE_INTENT_SUCCESS) {
    const newState = { ...state };
    localStorage.removeItem('intent');
    newState.intent = null;
    newState.clientSecret = null;
    newState.intentUpdated = false;
    return newState;
  }
  if (action.type === GeneralTypes.RESET_AFTER_PAYMENT_SUCCESS) {
    const newState = { ...state };
    localStorage.removeItem('intent');
    localStorage.removeItem('duration');
    localStorage.removeItem('offer');
    localStorage.removeItem('recurrentOption');
    localStorage.removeItem('transaction');
    localStorage.removeItem('gift');
    localStorage.removeItem('orderState');
    localStorage.removeItem('duratioName');
    localStorage.removeItem('hasGift');
    localStorage.removeItem('campaign');
    localStorage.removeItem('productID');
    localStorage.removeItem('editionID');
    newState.intent = null;
    newState.swgBuy = false;
    newState.clientSecret = null;
    newState.intentUpdated = false;
    newState.hasGift = null;
    newState.transaction = null;
    newState.gift = null;
    newState.campaign = null;
    newState.productID = null;
    newState.editionID = null;
    newState.orderState = 'INIT';
    return newState;
  }
  if (action.type === GeneralTypes.UPDATE_INTENT_SUCCESS) {
    const newState = { ...state };
    newState.intentUpdated = true;
    localStorage.setItem('intent', action.data);
    newState.intent = action.data;
    return newState;
  }
  if (action.type === GeneralTypes.GET_CLIENT_SECRET) {
    const newState = { ...state };
    newState.clientSecret = action.data;
    return newState;
  }
  if (action.type === GeneralTypes.RESET_APP_STATE) {
    const newState = { ...state };
    newState.intent = null;
    newState.clientSecret = null;
    newState.intentUpdated = false;
    newState.offer = null;
    newState.swgBuy = false;
    newState.renewal = false;
    newState.orderState = 'INIT';
    newState.duration = null;
    newState.productID = null;
    newState.editionID = null;
    newState.order_id = null;
    newState.transaction = null;
    newState.hasGift = false;
    newState.gift = null;
    newState.campaign = null;
    localStorage.setItem('orderState', 'INIT');
    return newState;
  }
  if (action.type === GeneralTypes.PAYMENT_SUCCESS) {
    const newState = { ...state };
    if (state.currentProduct === null) {
      return state;
    }
    localStorage.setItem('order_id', action.data.id);
    newState.order_id = action.data.id;
    if (
      state.currentProduct.channelDto.name === 'INTÉGRAL' ||
      state.currentProduct.saturdayPaper
    ) {
      localStorage.setItem('orderState', 'PAID');
      newState.orderState = 'PAID';
    } else {
      localStorage.setItem('orderState', 'FINISHED');
      newState.orderState = 'FINISHED';
    }
    return newState;
  }
  if (action.type === GeneralTypes.DELIVERY_SUCCESS) {
    const newState = { ...state };
    newState.orderState = 'FINISHED';
    return newState;
  }

  return state;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function appActions(state: AppActions = null, action: any): AppActions {
  if (action.type === GeneralTypes.GET_APP_ACTIONS) return action.data;
  return state;
}

export default combineReducers({
  appState,
  appActions,
});
