import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Cookies from 'js-cookie';
import { DidomiSDK } from '@didomi/react';
import Layout from '../Layout';
import Voucher from '../../pages/Voucher';
// import Duration from '../Duration';
import Gift from '../Gift';
import Edition from '../Edition';
import LoggedIn from '../LoggedIn';
import Finished from '../Finished';
import { Select } from '../Payment';
import ContactData from '../../pages/ContactData';
import Delivery from '../../pages/Delivery';
import { RootProps } from '.';
import LoadingScreen from '../LoadingScreen';
import './root.css';
import Promotion from '../Promotion';
import AuthPiano from '../AuthPiano';
import { GeneralTypes, UserTypes } from '../../actions/types';
import {
  ABORENEWAL_PAGE,
  DELIVERY_PAGE,
  DURATION_PAGE,
  EDITION_PAGE,
  FINISHED_PAGE,
  GIFT_PAGE,
  GOTO_PAGE,
  HOME_PAGE,
  PAY_PAGE,
  PROMOTION_PAGE,
  DELIVERY_ADDRESS_PAGE,
  VOUCHER_PAGE,
  CONTACT_DATA_PAGE,
  BILLING_ADDRESS_PAGE,
  SHORT_FORM_PAGE,
  USER_LOGIN_PAGE,
  TEMPORARY_OFFERS_PAGE,
  ERROR_PAGE,
  PAY_RENEWAL_PAGE,
  FREE_CAMPAIGN_PAGE,
  COOKIE_PAGE,
} from '../../utils/routes';
import Billing from '../../pages/Billing';
import ShortForm from '../../pages/ShortForm';
import Login from '../../pages/User';
import FreeCampaign from '../../pages/FreeCampaign';
import Duration from '../../pages/Duration';
import TemporaryOffers from '../../pages/TemporaryOffers';
import ErrorPage from '../../pages/Error/ErrorPage';
import usePageViews from '../../hooks/usePageViews';
import Home from '../../pages/HomePage';
import Renewal from '../Renewal';
import PayRenewal from '../Renewal/PayRenewal/PayRenewal';
import Goto from '../../pages/Goto';
import loadPianoScript from '../AuthPiano/loadPianoScript';
import browserHistory from '../../utils/browser_history';
import { IBrand, getCurrentBrand } from '../../utils/brands';
import Cookie from '../../pages/Cookie';
import {
  triggerLoginSuccess,
  triggerRegistrationSuccess,
} from '../AuthPiano/PianoID';
import loadGTM from '../GTM/loadGTM';
import loadAdBlockerScript from '../AuthPiano/loadAdBlockerScript';

declare global {
  interface Window {
    pdl: any;
  }
}
window.cache = window.cache || {};
window.pdl = window.pdl || {};

function LoggedInRoute({ component: Component, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={(props: any) => (
        <LoggedIn {...props}>
          <Component {...props} />
        </LoggedIn>
      )}
    />
  );
}

const App = (props: RootProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  usePageViews(props.general.appState, props.user);
  const { brand, swgHasBeenInitialized, didomiInitialized } =
    props.general.appState;

  const [isReady, setIsReady] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [logginToken, setLogginToken] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUserAdded, setIsUserAdded] = useState<boolean>(false);
  const [hasAdblocker, setHasAdblocker] = useState<boolean>(false);
  const [hideAdblocker, setHideAdblocker] = useState<boolean>(false);
  const [currentBrand, setCurrentBrand] = useState<IBrand>();
  const getFaviconEl = () => document.getElementById('favicon') as any;

  useEffect(() => {
    // show swg buttons when the users are going back to the homepage, loadPianoScripts handles displaying
    if (location.pathname === '/' && swgHasBeenInitialized) {
      window.dispatchEvent(new Event('showSwgButton'));
    }
    setHideAdblocker(false);
  }, [location, swgHasBeenInitialized]);

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!currentBrand) {
          setCurrentBrand(getCurrentBrand(brand.name));
        }
        if (
          currentBrand !== undefined &&
          (didomiInitialized === true || didomiInitialized === false)
        ) {
          await loadAdBlockerScript({
            brand,
            setIsAdblockerLoaded: (status: boolean) =>
              dispatch({
                type: GeneralTypes.NOADBLOCK_INITIALIZED,
                data: status,
              }),
          });
          await loadPianoScript({
            brand,
            currentBrand,
            didomiInitialized,
            setIsPianoLoaded: (status: boolean) =>
              dispatch({ type: GeneralTypes.PIANO_INITIALIZED, data: status }),
            setIsSWGLoaded: (status: boolean) => {
              dispatch({ type: GeneralTypes.SWG_INITIALIZED, data: status });
            },
            externalCheckoutComplete: () => {
              const swgData: any = { swgBuy: true, currentProduct: null };
              dispatch({ type: GeneralTypes.SWG_SUCCESS, data: swgData });
              browserHistory.push(FINISHED_PAGE);
            },
            loginSuccess: (tp: any, data: any) => {
              triggerLoginSuccess(tp, data);
            },
            registrationSuccess: (tp: any, data: any) => {
              triggerRegistrationSuccess(tp, data);
            },
          });
          await loadGTM({
            brand: currentBrand,
            env: process.env.REACT_APP_ENV || 'default',
            setIsGTMLoaded: (status: boolean) => {
              dispatch({ type: GeneralTypes.GTM_INITIALIZED, data: status });
            },
          });
        }
      } catch (err: any) {
        setError(err.message);
      }
    };
    if (brand !== null && brand.aid !== undefined) {
      window.addEventListener('unable_to_get_script', () => {
        setHasAdblocker(true);
      });
      fetch().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand, didomiInitialized]);

  useEffect(() => {
    const fetch = async () => {
      try {
        await props.actions.initApp();
        const brandCall = await props.actions.GetBrand();
        const favicon = getFaviconEl();
        // eslint-disable-next-line global-require,import/no-dynamic-require
        favicon.href = require(`../../images/ico-${brandCall.name}.ico`);
        setIsReady(true);
      } catch (err: any) {
        setError(err.message);
      }
    };

    fetch().then();

    window.addEventListener(
      'message',
      ({ data }: any) => {
        try {
          const { sender, event }: { sender: string; event: string } =
            JSON.parse(data);
          if (sender.includes('piano-id') && event === 'closed') {
            setIsModalOpen(false);
          }
          // eslint-disable-next-line no-empty
        } catch {}
      },
      false
    );
  }, [props.actions]);

  const handleModalChange = () => {
    if (isModalOpen) {
      return;
    }

    if (
      props.user.currentUser.isAuth &&
      !props.user.currentUser.isValid &&
      props.currentPath === PAY_PAGE
    ) {
      if (!isModalOpen) {
        setIsModalOpen(true);
      }
    } else {
      setIsModalOpen(!isModalOpen);
    }
  };

  const handleloggedIn = async (e: any) => {
    if (logginToken !== e.token) {
      setLogginToken(e.token);
      dispatch({
        type: UserTypes.LOGIN_SUCCESS,
        data: e,
      });
      localStorage.setItem('token', e.token);
      handleModalChange();
      await props.actions.addUser(
        e.user.email,
        e.user.firstName,
        e.user.lastName,
        e.user.valid
      );
      setIsUserAdded(true);
      setIsModalOpen(false);
    }
  };

  const canGoCookie = (page: string): boolean => {
    if (
      process.env.REACT_APP_ENV === 'PROD' ||
      process.env.NODE_ENV === 'development'
    )
      return true;
    const cookie = Cookies.get('aboshop-access');
    const hash = currentBrand?.cookie;
    if (cookie === hash) {
      return true;
    }
    const { productID, renewal } = props.general.appState;
    if (page === PAY_PAGE) {
      return productID !== null;
    }
    if (page === PAY_RENEWAL_PAGE && renewal) {
      return renewal;
    }
    return false;
  };

  const canGo = (page: string): boolean => {
    const { offer, duration, productID, renewal } = props.general.appState;

    if (page === GIFT_PAGE) {
      return !(offer === null || duration === null);
    }

    if (page === EDITION_PAGE) {
      return duration !== null || productID !== null;
    }

    if (page === PAY_PAGE) {
      return productID !== null;
    }

    if (page === PAY_RENEWAL_PAGE && renewal) {
      return renewal;
    }

    return false;
  };

  if (!isReady) {
    return (
      <div className="lds-container">
        <LoadingScreen customColor="#808080" size={60} />
      </div>
    );
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* <!-- HTML Meta Tags --> */}
          <title>{props.general.appState.brand.metaTitle}</title>
          <meta name="title" content={props.general.appState.brand.metaTitle} />
          <meta
            name="description"
            content={props.general.appState.brand.metaDescription}
          />
        </Helmet>
      </HelmetProvider>
      {brand.aid && currentBrand?.didomi !== undefined ? (
        <DidomiSDK
          apiKey={process.env.REACT_APP_DIDOMI_API_KEY}
          noticeId={currentBrand.didomi}
          iabVersion={2}
          gdprAppliesGlobally
          onReady={() =>
            dispatch({ type: GeneralTypes.DIDOMI_INITIALIZED, data: true })
          }
        />
      ) : (
        dispatch({ type: GeneralTypes.DIDOMI_INITIALIZED, data: false })
      )}
      <Layout
        location={window.location.pathname}
        logout={props.actions.logout}
        loggedIn={props.user.currentUser.isAuth}
        openLogginModal={handleModalChange}
        isModalOpen={isModalOpen}
        user={props.user}
        general={props.general}
        dispatch={dispatch}
        hasAdblocker={hasAdblocker}
        toggleHideAdblocker={() => setHideAdblocker(!hideAdblocker)}
        hideAdblocker={hideAdblocker}
      >
        {error !== '' ? error : null}
        <Switch>
          <Route
            path={HOME_PAGE}
            exact
            render={() =>
              canGoCookie(HOME_PAGE) ? (
                <Home openLogginModal={handleModalChange} />
              ) : (
                <Redirect to="/cookie/nohash" />
              )
            }
          />
          <Route
            path={FREE_CAMPAIGN_PAGE}
            render={() => <FreeCampaign openLogginModal={handleModalChange} />}
          />
          <Route path={COOKIE_PAGE} component={Cookie} />
          <Route path={TEMPORARY_OFFERS_PAGE} component={TemporaryOffers} />
          <Route path={VOUCHER_PAGE} component={Voucher} />
          <Route path={PROMOTION_PAGE} component={Promotion} />
          <Route path={GOTO_PAGE} component={Goto} />
          {/* <Route path={ABORENEWAL_PAGE} component={Renewal} /> */}
          <Route path={ABORENEWAL_PAGE} component={Renewal} />
          {/* <Route path={DURATION_PAGE} component={Duration} /> */}
          <Route path={DURATION_PAGE} render={() => <Duration />} />
          <Route
            path={PAY_RENEWAL_PAGE}
            exact
            render={() =>
              canGoCookie(PAY_RENEWAL_PAGE) ? (
                <PayRenewal />
              ) : (
                <Redirect to={HOME_PAGE} />
              )
            }
          />
          <Route path={DELIVERY_ADDRESS_PAGE} component={Delivery} />
          <Route path={USER_LOGIN_PAGE} component={Login} />
          <LoggedInRoute path={BILLING_ADDRESS_PAGE} component={Billing} />
          <LoggedInRoute path={SHORT_FORM_PAGE} component={ShortForm} />
          <LoggedInRoute path={CONTACT_DATA_PAGE} component={ContactData} />
          <Route
            path={GIFT_PAGE}
            render={() =>
              canGo(GIFT_PAGE) ? <Gift /> : <Redirect to={HOME_PAGE} />
            }
          />
          <Route
            path={EDITION_PAGE}
            render={() =>
              canGo(EDITION_PAGE) ? <Edition /> : <Redirect to={HOME_PAGE} />
            }
          />
          <Route
            path={PAY_PAGE}
            exact
            render={() =>
              canGoCookie(PAY_PAGE) ? (
                <Select openLogginModal={handleModalChange} />
              ) : (
                <Redirect to={HOME_PAGE} />
              )
            }
          />
          <LoggedInRoute path={DELIVERY_PAGE} component={Delivery} />
          <LoggedInRoute path={FINISHED_PAGE} component={Finished} />
          <Route path={ERROR_PAGE} component={ErrorPage} />
          <Route render={() => <Redirect to={HOME_PAGE} />} />
        </Switch>
        {isModalOpen && (
          <AuthPiano
            handleloggedIn={async (val: any) => handleloggedIn(val)}
            authType="login"
            asModal
          />
        )}
      </Layout>
    </>
  );
};

export default App;
