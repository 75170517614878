import { BrandType } from '../../reducers/entities/general';

const loadAdBlockerScript = async ({ brand, setIsAdblockerLoaded }: any) => {
  const { aid } = brand as BrandType;

  if (typeof window !== 'undefined' && aid) {
    document.cookie =
      '__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    const setNptTechAdblockerCookie = (adblocker: boolean) => {
      const d = new Date();
      d.setTime(d.getTime() + 60 * 5 * 1000);
      document.cookie = `_adblocker=${
        adblocker ? 'true' : 'false'
      }; expires=${d.toUTCString()}; path=/`;
    };

    const script = document.createElement('script');
    script.src = '//www.npttech.com/advertising.js';
    script.id = 'adblocker-script';
    script.async = true;

    script.onerror = () => {
      setNptTechAdblockerCookie(true);
    };

    setIsAdblockerLoaded(true);
    document.getElementsByTagName('head')[0].appendChild(script);
  }
};

export default loadAdBlockerScript;
